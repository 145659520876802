
import { Component, Vue } from 'vue-property-decorator';
import Grid from '@/components/grid/Grid';
import GridCell from '@/components/grid/GridCell';
import FullScreenForm from '@/components/FullScreenForm.vue';
import GButton from '@/components/gsk-components/GskButton.vue';
import GSelect from '@/components/gsk-components/GskSelect.vue';
import { RawLocation } from 'vue-router';
import { RouteNames } from '@/constants';
import { RpaOpsModule } from '@/store/om27.module';
import {
  LogData,
  LogicalBot,
  SignOffData,
  SignOffFilters,
  UserPreferences,
  SignOffDataEvidenceFile,
} from '@/types/om27.types';
import DataGrid from '@/components/DataGrid.vue';
import { Promised } from 'vue-promised';
import { addVerification, getRpaOpsSignOff, addVerificationComment } from '@/api/om27.api';
import { ValidationObserver } from 'vee-validate';
import SignOffGrid from '@/components/SignOffGrid.vue';
import { orderBy, uniq } from 'lodash';
import { formatDate } from '@/utils/datetime';
import NewlineText from '@/components/NewlineText.vue';
import BlockingTickets from '@/components/BlockingTickets.vue';
import VerificationsTable from '@/components/VerificationsTable.vue';
import { SelectField, TextField } from '@/components/form/form.types';
import ValidatedFormDialog from '@/components/dialogs/ValidatedFormDialog.vue';
import { openErrorSnackbar, openSuccessSnackbar } from '@/utils/components';
import {
  VerificationCode,
  VerificationPayload,
  VerificationStatus,
  VerificationCommentPayload,
} from '@/types/signoff.types';
// import FeatureFlag from '@/components/FeatureFlag';
import { getFilePreviewUrl } from '@/api/sharepoint.api';
import { SignOffHistoryItem } from '@/types/om27.types';

type VerifyFormModel = [
  SelectField<'sign_off_status'>,
  SelectField<'verification_code'>,
  TextField<'notes'>,
  TextField<'ticket_number'>,
];

type CommentFormModel = [
  TextField<'comment'>,
];

@Component({
  components: {
    Grid,
    GridCell,
    FullScreenForm,
    GButton,
    GSelect,
    DataGrid,
    Promised,
    SignOffGrid,
    NewlineText,
    BlockingTickets,
    VerificationsTable,
    ValidatedFormDialog,
    // FeatureFlag,
  },
})
export default class SignOff extends Vue {
  closeRoute: RawLocation = {
    name: RouteNames.Om27,
  };

  $refs!: {
    addNewNoteDialog: InstanceType<typeof ValidationObserver>;
    addNewCommentDialog: InstanceType<typeof ValidationObserver>;
  };

  get timezone(): UserPreferences['preferred_timezone'] {
    return RpaOpsModule.userPreferences.preferred_timezone;
  }

  get timezone_short(): UserPreferences['timezone_short'] {
    return RpaOpsModule.userPreferences.timezone_short;
  }

  get logicalBotId(): number {
    return parseInt(this.$route.params.logicalBotId, 10);
  }

  get signOffId(): number {
    return parseInt(this.$route.params.signOffId, 10);
  }

  get logicalBot(): LogicalBot | undefined {
    return RpaOpsModule.logicalBots.find(bot => bot.logicalBotId === this.logicalBotId);
  }

  get prevNextUrls() {
    const { signOffHistory } = RpaOpsModule;
    let prev = null;
    let next = null;

    if (!signOffHistory) {
      return { prev, next };
    }
    const currentIndex = signOffHistory?.findIndex(
      record => record.signoffId === this.signOffId,
    );
    if (currentIndex < 0) {
      return { prev, next };
    }
    const prevIndex = currentIndex - 1;
    const nextIndex = currentIndex + 1;

    if (prevIndex >= 0) {
      prev = this.constructRouteForSignOffId(
        signOffHistory[prevIndex].signoffId,
      );
    }

    if (nextIndex < signOffHistory.length) {
      next = this.constructRouteForSignOffId(
        signOffHistory[nextIndex].signoffId,
      );
    }
    return { prev, next };
  }

  signOffDataEvidenceFiles: SignOffDataEvidenceFile[] = [];
  get evidenceFiles(): any {
    if (!this.signOffDataEvidenceFiles) {
      return [];
    }
    return this.signOffDataEvidenceFiles.map((file) => ({
      name: file.file_name,
      url: getFilePreviewUrl(file.file_name, file.file_path),
    }));
  }

  constructRouteForSignOffId(signOffId: number): RawLocation {
    return {
      name: RouteNames.RpaOpsSignOffDetails,
      params: {
        logicalBotId: String(this.logicalBotId),
        signOffId: String(signOffId),
      },
    };
  }

  date(date: string): string {
    if (!date) {
      return '—';
    }
    return formatDate(date, this.timezone, {
      timeOnly: false,
      dateOnly: false,
      timezoneAbbrv: this.timezone_short,
    });
  }

  goTo(route: RawLocation | null) {
    if (!route) {
      return;
    }
    this.$router.replace(route);
    this.$nextTick(() => {
      this.loadDetails();
      this.selectedRunnerId = '';
    });
  }

  signOffForDescDateFormatted = '';
  selectedRunnerId = '';
  signOffUserMudId = '';

  isCommentDialogOpen = false;
  isCommentDialogLoading = false;
  commentDialogFormModel: CommentFormModel = [
    {
      key: 'comment',
      type: 'long-text',
      value: '',
      label: 'Comment',
      validation: {
        rules: {
          required: true,
        },
      },
    },
  ];

  resetVerificationCommentForm() {
    for (const field of this.commentDialogFormModel) {
      field.value = '';
    }
  }

  async onSubmitVerificationComment() {
    this.isCommentDialogLoading = true;
    try {
      const commentFormData: VerificationCommentPayload = {
        signOffId: this.signOffId,
        comment: String(this.commentDialogFormModel.find(f => f.key === 'comment')?.value),
      };
      let signerId = this.signOffUserMudId;
      if (this.signOffUserMudId.toUpperCase().includes('AUTO')) {
        signerId = '';
      }
      await addVerificationComment(commentFormData, signerId);
      await this.getSignOff(true);
      openSuccessSnackbar.call(this, 'Comment submitted.');
      this.isCommentDialogOpen = false;
    } catch (ex: any) {
      const msg = ex.response?.data?.error || ex.response?.data?.message || ex;
      openErrorSnackbar.call(this, msg);
    } finally {
      this.isCommentDialogLoading = false;
    }
  }

  verifyOpen = false;
  verifyLoading = false;
  verifyFormModel: VerifyFormModel = [
    {
      key: 'sign_off_status',
      options: ['VERIFIED', 'IN PROGRESS', 'FAILED'],
      value: '',
      type: 'select',
      label: 'Status',
      validation: {
        rules: {
          required: true,
        },
      },
    },
    {
      key: 'verification_code',
      type: 'select',
      label: 'Verification Code',
      options: ['VERIFIED', 'IN PROGRESS', 'BADUNKNOWN', 'BADDETAILS', 'BOTFAILED'],
      value: '',
      validation: {
        rules: {
          required: true,
        },
      },
    },
    {
      key: 'notes',
      type: 'long-text',
      value: '',
      label: 'Verification Notes',
      validation: {
        rules: {
          required: true,
        },
      },
    },
    {
      key: 'ticket_number',
      type: 'text',
      value: '',
      label: 'Ticket Number (optional)',
    },
  ];

  get verifyFormData(): VerificationPayload {
    const data: VerificationPayload = {
      sign_off_id: this.signOffId,
      notes: this.verifyFormModel[2].value,
      sign_off_status: this.verifyFormModel[0].value as VerificationStatus,
      verification_code: this.verifyFormModel[1].value as VerificationCode,
    };

    const ticketNumber = this.verifyFormModel[3].value;
    if (ticketNumber) {
      data.ticket_number = ticketNumber;
    }
    return data;
  }
  resetVerifyForm() {
    for (const field of this.verifyFormModel) {
      field.value = '';
    }
  }
  async verify() {
    this.verifyLoading = true;
    try {
      await addVerification(this.verifyFormData);
      await this.getSignOff(true);
      openSuccessSnackbar.call(this, 'Verification added');
      this.verifyOpen = false;
    } catch (e: any) {
      const msg = e.response?.data?.error || e.response?.data?.message || e;
      openErrorSnackbar.call(this, msg);
    } finally {
      this.verifyLoading = false;
    }
  }

  onDialogClosed() {
    this.resetVerifyForm();
    this.resetVerificationCommentForm();
    this.$nextTick(() => {
      this.$refs.addNewNoteDialog.reset();
      this.$refs.addNewCommentDialog.reset();
    });
  }

  get bots(): LogicalBot[] {
    if (this.logicalBot) {
      return [this.logicalBot];
    }

    return [];
  }

  get selectedBots(): number[] {
    return this.bots.map(bot => bot.logicalBotId);
  }

  get runnerIds(): string[] {
    return uniq(this.bots
      .map(b => b.subRecords.map(s => s.botRunnerUserId))
      .filter(runnerId => runnerId)
      .flat());
  }

  sortDirs: [string[], string[]] | [] = [];
  sort(rows: LogData[]) {
    return orderBy(rows, ...this.sortDirs);
  }
  filter(rows: LogData[]) {
    return rows
      .filter(row => {
        const { fileName: target } = this.activeFilters;
        if (target.length) {
          return target.includes(row.fileName);
        }
        return true;
      })
      .filter(row => {
        const { machineNameShort: target } = this.activeFilters;
        if (target.length) {
          return target.includes(row.machineNameShort);
        }
        return true;
      })
      .filter(row => {
        if (this.selectedRunnerId) {
          return row.botRunnerUserId === this.selectedRunnerId;
        }
        return row;
      });
  }

  goToDashboard(): void {
    this.$router
      .replace({
        name: RouteNames.Om27,
        query: this.$route.query || {
          expand: this.logicalBotId.toString(),
        },
      })
      .catch(() => void 0);
  }

  filters: SignOffFilters = {
    machineNameShort: [],
    fileName: [],
  };
  activeFilters: SignOffFilters = {
    machineNameShort: [],
    fileName: [],
  };

  signOffPromise: Promise<SignOffData> | null = null;
  signOffTicketUrl: string | undefined;
  async getSignOff(hideLoader = false) {
    const res = RpaOpsModule.getSignOffData(this.signOffId);
    if (!hideLoader) {
      this.signOffPromise = res;
    }
    res
      .then(data => {
        this.signOffForDescDateFormatted = data.signofffordesc;
        this.signOffDataEvidenceFiles = data.files;
        this.signOffUserMudId = data.closure?.signoffHumanUserMudId || '';

        const signOffTicketNumber = data.closure?.ticketNumber;
        if (signOffTicketNumber) {
          const signOffTicket = data.tickets?.find((ticket) => ticket.ticketNumber === signOffTicketNumber);
          if (signOffTicket) {
            this.signOffTicketUrl = signOffTicket.ticketUrl;
          }
        }
        // get filters
        const targets = data.logData
          .map(d => {
            const { fileName, machineNameShort } = d;
            return {
              fileName,
              machineNameShort,
            };
          })
          .reduce(
            (acc, obj) => {
              acc.fileName.add(obj.fileName);
              acc.machineNameShort.add(obj.machineNameShort);
              return acc;
            },
            { fileName: new Set() as Set<string>, machineNameShort: new Set() as Set<string> },
          );
        this.filters.machineNameShort = [...targets.machineNameShort];
        this.filters.fileName = [...targets.fileName];
      })
      .then(() => {
        this.signOffPromise = res;
      });
  }

  promise: Promise<unknown> | null = null;
  async loadDetails() {
    if (!RpaOpsModule.logicalBots.length) {
      this.promise = RpaOpsModule.getDashboard();
    } else {
      this.promise = Promise.resolve();
    }

    await this.promise;

    if (!this.logicalBot) {
      this.goToDashboard();
    }
    this.getSignOff();

    if (!RpaOpsModule.signOffHistory) {
      await RpaOpsModule.getSignOffHistoryData(this.logicalBotId);
    }
  }

  async created(): Promise<void> {
    this.loadDetails();
  }
}
